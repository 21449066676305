import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { SuperAdminService } from 'src/app/role/super-admin/super-admin.service';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit {

  @Output('countTrigger') countTrigger = new EventEmitter();
  notifications$: Observable<any>;
  isNotificationAvailable: boolean;
  notifications: any[] = [];
  loading = false;
  initialLoad = false;
  page = 1;
  pageSize = 10;
  hasMore = true;

  constructor(private superAdminService: SuperAdminService) { }

  ngOnInit() {
    this.loadNotifications();
  }

  loadNotifications(): void {
    if (this.loading || !this.hasMore) return;
    this.loading = true;
    this.initialLoad = true;
    this.superAdminService.getNotifications(this.page, this.pageSize).subscribe(
      (data: any) => {
        this.notifications$ = of([...this.notifications, ...data.items]);
        this.hasMore = data.total_pages !== this.page;
        this.loading = false;
        this.page++;
        this.notifications$.subscribe(notifications => {
          this.notifications = notifications;
        });
        let count = data.user_unread_count === 0 ? null : data.user_unread_count;
        this.countTrigger.emit(count);
        this.isNotificationAvailable = Boolean(count);
      },
      (error) => {
        console.error('Error loading notifications:', error);
        this.loading = false;
        this.isNotificationAvailable = false;
      }
    );
  }

  onScroll(event: any): void {
    this.initialLoad = false;
    const element = event.target;
    if (element.scrollHeight - element.scrollTop <= element.clientHeight + 100) {
      this.loadNotifications();
    }
  }

  updateNotification(action: string, notification) {
    const {id} = notification;
    const payload = {
      notification_ids: [id],
      operation: action
    }
    this.superAdminService.updateNotification(payload).subscribe((res: any) => {
      if(res) this.updateNotifications$(id);
    }, (err) => {
      console.error(err.error);
      this.isNotificationAvailable = false;
    });
  }

  updateNotifications$(id: number) {
    const index = this.notifications.findIndex(data => data.id === 5)
    this.notifications.splice(1, index);
    this.notifications$ = of(this.notifications);
    this.countTrigger.emit(null);
  }

  updateAllNotifications(action: string) {
    const payload = {
      operation: action
    };
    this.superAdminService.updateNotification(payload).subscribe((res: any) => {
      if(res) this.updateAllNotifications$(action);
    }, (err) => {
      console.error(err.error);
      this.isNotificationAvailable = false;
    });
  }

  updateAllNotifications$(action) {
    if(action === 'READ_ALL') {
      this.notifications = this.notifications.map(notification => {
        notification.is_read = true;
        return notification;
      })
    } else {
      this.notifications = [];
    }
    this.notifications$ = of(this.notifications);
    this.countTrigger.emit(null);
  }

  timeFromNow(date: string): string {
    return moment(date).fromNow();
  }

}
